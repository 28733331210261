import React, { Component } from 'react';
import { connect } from 'react-redux';

import Color from '../Color';
import Selina_Productlist from './Selina_Productlist';
import Selina_Graphiclist from './Selina_Graphiclist';

class Selina_LeftDashboard extends Component {
  render() {
    return (
      <div className={'option-window float-left left-dashboard'}>
        <div className='detail-block'>
          <div className='title'>
          </div>
          <div>Product</div>

          <Selina_Productlist />
        </div>
        <div className='detail-block'>
          <div className='title'>
          </div>
          <Selina_Graphiclist />
        </div>
      </div>
    )
  }
}

export default connect()(Selina_LeftDashboard);
