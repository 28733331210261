import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import {updateGraphic} from '../../actions/graphic-actions';
import {updateProduct} from '../../actions/product-actions';
import {updateColor} from '../../actions/color-actions';
import Color from '../Color';
import Size from '../Size';
import SizechartList from '../SizechartList';


class FASC_RightDashboard extends Component {

  render() {
      return (
        <div className={'option-window float-right right-dashboard'}>
          <div className='color-block'>
            <div className='title'>
            </div>
            <div>Color</div>
            <Color />
          </div>

        </div>

      )
  }
}

const mapStateToProps = (state, props)=>{
  return{
    characters: state.characters,
    character: state.character,

    products: state.products,
    product: state.product,

    colors: state.colors,
    color: state.color,

    sizes: state.sizes,
    size: state.size,

    graphics: state.graphics,
    graphic: state.graphic,

    graphicColor: state.graphicColor,

  }
};

const mapActionToProps = {
  updateGraphic: updateGraphic,
  updateProduct: updateProduct,
  updateColor: updateColor,
};

export default connect(mapStateToProps, mapActionToProps)(FASC_RightDashboard);
