import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

class Header extends Component {
  render() {
    return (
      <div className="header">
        <Link to="/sizechart" style={{ textDecoration: 'none' }}>
          <div className="header-button">
            <div className="header-text">SIZECHART</div>
          </div>
        </Link>
        <Link to="/fasc" style={{ textDecoration: 'none' }}>
          <div className="header-button">
            <div className="header-text">CREATOR</div>
          </div>
        </Link>
      </div>
    )
  }
}

export default connect()(Header);
