import React, { Component } from 'react';
import { connect } from 'react-redux';

import {updateGraphic} from '../actions/graphic-actions';
import {updateProduct} from '../actions/product-actions';
import {updateColor} from '../actions/color-actions';
import {updateSize} from '../actions/size-actions';
import {updateGraphicColor} from '../actions/graphic-color-actions';
import DatePicker from 'react-date-picker';

import LeftDashboard from './LeftDashboard';
import Graphic from './Graphic';
import RightDashboard from './RightDashboard';

import emailjs from 'emailjs-com';
import Popup from "reactjs-popup";



function sendEmail(e) {
  e.preventDefault();

  emailjs.sendForm('valters_fasc_at', 'gwond', e.target, 'user_jjmRMDEFrj4Ml7guVwza0')
    .then((result) => {
        if (document.getElementById('content')) {

          if (document.getElementById('content').style.display == 'none') {
              document.getElementById('content').style.display = 'block';
              document.getElementById('success').style.display = 'none';
          }
          else {
              document.getElementById('content').style.display = 'none';
              document.getElementById('success').style.display = 'block';
          }
        }
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
}


class Main extends Component {
  constructor (props) {
    super(props);

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    this.state = {
        date: tomorrow,
        imgurl: '',
        imgbg: '',
        cordoverlay: '../../public/images/cords_overlay.png',
    }

    //Needed for the initial load: take first characters "welcome" parameters
    this.props.updateGraphic(this.props.graphics[0]);
    this.props.updateColor(this.props.colors[0]);
    this.props.updateProduct(this.props.products[0]);
    this.props.updateSize(this.props.sizes[1]);
    this.props.updateGraphicColor(this.props.graphicColors[0]);

  }

  onChange = date => this.setState({ date })


  componentDidUpdate() {
    this.updateImgUrl()
    this.updateBG()
  }

  updateImgUrl(){
    const images = require.context('../../public/images', true);
    const imgsrc = images(`./${this.props.product.folder}/${this.props.color.color}.png`);
    const imgdef = images(`./${this.props.product.folder}/black.png`);

    if (this.state.imgurl !== imgsrc) {
      this.setState({imgurl: imgsrc})
      if (this.state.imgsrc){
        this.setState({imgurl: imgdef})
      }
    }
  }
  updateBG(){
    const images = require.context('../../public/images', true);
    const imgbg = images(`./bg.jpg`);
    const cordoverlay = images('./cordoverlay.png');
    const gschaeftl_logo = images('./gschaeftl_logo.png');



    if (this.state.imgbg !== imgbg) {
      this.setState({imgbg: imgbg})
      this.setState({cordoverlay: cordoverlay})
      this.setState({gschaeftl_logo: gschaeftl_logo})
    }
  }

  render() {
    if (!this.props.graphic.name){
      return (<h2>Sorry, something went terribly wrong.</h2>);
    }

    else {
      return (
        <div>
        <Popup trigger={<button className="order-button sticky">NEXT STEP</button>} modal>
            {close => (
              <div className="modal">
                <a className="close" onClick={close}>
                  &times;
                </a>
                <div className="content" id="content">

                  <div class="subscribe-box">
                    <div
                      className='popup-logo'
                      style={{backgroundImage: 'url(' + this.state.gschaeftl_logo + ')'}}
                    ></div>
                    <h2>Your clothing will be ready to pick up the next day between 15:00 and 18:00 at Gschäftl.
                    <br></br> <a target="_blank" href="https://goo.gl/maps/oBxjYFQahii1awoQ6">Grillparzerstraße 10, 5640 Bad Gastein</a></h2>
                    <form className="contact-form subscribe" onSubmit={sendEmail}>

                      <input type="hidden" name="Product" value={this.props.product.category}/>
                      <input type="hidden" name="Color" value={this.props.color.color}/>
                      <input type="hidden" name="Size" value={this.props.size.size}/>
                      <input type="hidden" name="Graphic" value={this.props.graphic.name}/>
                      <input type="hidden" name="GraphicColor" value={this.props.graphicColor.color}/>
                      <input type="hidden" name="Where" value={'Gschaeftl'}/>
                      <input type="hidden" name="Assigned" value={'mosi@fascwear.com'}/>

                      <input type="text" name="Name" placeholder="Your Name" autocomplete="off" required="required"/>
                      <input type="text" name="Phone" placeholder="Phone Number" autocomplete="off" required="required"/>
                      {/*
                      <DatePicker
                        onChange={this.onChange}
                        value={this.state.date}
                      />
                      <input type="hidden" name="Date" placeholder="Pick up date (Tomorrow if empty)" value={this.state.date} autocomplete="off" />
                      */}
                      <input type="text" name="Info" placeholder="Info (Optional)" autocomplete="off" />

                      <button onClick={this.success} className="order-button" type="submit"> <span>ORDER</span></button>
                    </form>
                  </div>
                </div>
                <div className="success" id="success">

                <div className="modal">
                  <a className="close" onClick={close}>
                    &times;
                  </a>
                  <div className="content" id="content">

                    <div class="subscribe-box">
                      <h2>Thank you so much!</h2>
                      <h3>You can always reach us under +436644641891 for any further questions!</h3>
                      <h3>Feel free to close this window and order another piece of the original Gastein Clothing!</h3>
                      <br></br> <a target="_blank" href="https://goo.gl/maps/oBxjYFQahii1awoQ6">Click here to find your way to Gschäftl</a>
                    </div>
                  </div>
                </div>

                </div>
              </div>
            )}
          </Popup>
          <LeftDashboard />
          <RightDashboard />
          <div
            className='bg-image'
            style={{backgroundImage: 'url(' + this.state.imgbg + ')'}}
          >
          </div>
          <div
            className='product-image'
            style={{backgroundImage: 'url(' + this.state.imgurl + ')'}}
          >
          </div>
          <Graphic />
          {this.props.product.category === 'Hoodie' ?
          <div
            className='product-image'
            style={{backgroundImage: 'url(' + this.state.cordoverlay + ')'}}
          >
          </div> : ''}

        </div>
      )
    }
  }
}

const mapStateToProps = (state, props)=>{
  return{
    graphics: state.graphics,
    graphic: state.graphic,

    products: state.products,
    product: state.product,

    colors: state.colors,
    color: state.color,

    sizes: state.sizes,
    size: state.size,

    graphicColor: state.graphicColor,
    graphicColors: state.graphicColors,

  }
};

const mapActionToProps = {
  updateGraphic: updateGraphic,
  updateProduct: updateProduct,
  updateColor: updateColor,
  updateSize: updateSize,
  updateGraphicColor: updateGraphicColor,
};

export default connect(mapStateToProps, mapActionToProps)(Main);
