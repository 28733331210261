export default function () {
  return [
    {
      id: 0,
      size: 'XS',
      hoodieHeightCM: '70cm',
      hoodieWidthCM: '46cm',
      tshirtHeightCM: '0cm',
      tshirtWidthCM: '0cm',
    },
    {
      id: 1,
      size: 'S',
      hoodieHeightCM: '72cm',
      hoodieWidthCM: '47cm',
      tshirtHeightCM: '74cm',
      tshirtWidthCM: '50cm',
    },
    {
      id: 2,
      size: 'M',
      hoodieHeightCM: '74cm',
      hoodieWidthCM: '49cm',
      tshirtHeightCM: '77cm',
      tshirtWidthCM: '52cm',
    },
    {
      id: 3,
      size: 'L',
      hoodieHeightCM: '76cm',
      hoodieWidthCM: '51cm',
      tshirtHeightCM: '79cm',
      tshirtWidthCM: '54cm',
    },
    {
      id: 4,
      size: 'XL',
      hoodieHeightCM: '78cm',
      hoodieWidthCM: '53cm',
      tshirtHeightCM: '81cm',
      tshirtWidthCM: '56cm',
    },
    {
      id: 5,
      size: '2XL',
      hoodieHeightCM: '84cm',
      hoodieWidthCM: '58cm',
    },
    {
      id: 6,
      size: '3XL',
      hoodieHeightCM: '88cm',
      hoodieWidthCM: '57cm',
      tshirtHeightCM: '0cm',
      tshirtWidthCM: '0cm',
    },
    {
      id: 7,
      size: '4XL',
      hoodieHeightCM: '92cm',
      hoodieWidthCM: '57cm',
      tshirtHeightCM: '0cm',
      tshirtWidthCM: '0cm',
    },
    {
      id: 8,
      size: '116 cm',
    },
    {
      id: 9,
      size: '128 cm',
    },
    {
      id: 10,
      size: '140 cm',
    },
    {
      id: 11,
      size: '152 cm',
    }
  ]
}
