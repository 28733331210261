import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateColor} from '../actions/color-actions';
import {updateSize} from '../actions/size-actions';



class SizechartList extends Component {
  constructor (props) {
    super(props);

    this.state = {
      height: '',
      width: '',
    }
  }

  componentDidUpdate() {
    this.createSizechartTable()

  }


  createSizechartTable(){
    if (this.state.height !== this.props.size.hoodieHeightCM) {
      if (this.props.product.category === 'Hoodie') {
        this.setState({ height: this.props.size.hoodieHeightCM })
        this.setState({ width: this.props.size.hoodieWidthCM })
      }
      else {
        this.setState({ height: this.props.size.thirtHeightCM })
        this.setState({ width: this.props.size.tshirtWidthCM })
      }
    }


  }

  render() {

    return(
      <div>
        <div className="sizehart-table">
          <div>A: {this.state.height}</div>
          <div>B: {this.state.width}</div>

        </div>
      </div>
    )

  }
}

const mapStateToProps = (state, props)=>{
  return{
    product: state.product,
    colors: state.colors,
    color: state.color,
    sizes: state.sizes,
    size: state.size,
  }
};

const mapActionToProps = {
  updateColor: updateColor,
  updateSize: updateSize,
};
export default connect(mapStateToProps, mapActionToProps)(SizechartList);
