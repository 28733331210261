import { UPDATE_GRAPHIC } from '../actions/graphic-actions';

export default function characterReducer(state = '', { type, payload
  }) {
  switch (type) {
    case UPDATE_GRAPHIC :
      return payload;
    default:
      return state;
  }
}
