import React, { Component } from 'react';
import { connect } from 'react-redux';

import {updateGraphic} from '../actions/graphic-actions';
import {updateProduct} from '../actions/product-actions';
import {updateColor} from '../actions/color-actions';


class SizechartImage extends Component {
  constructor (props) {
    super(props);

    this.state = {
        imgurl: '',
    }
  }

  componentDidUpdate() {
    this.updateImgUrl()
  }
  updateImgUrl(){

    const images = require.context('../../public/images', true);
    const imgsrc = images(`./sizechart/sizechart.png`);

    if (this.state.imgurl !== imgsrc) {
      this.setState({imgurl: imgsrc})
    }
  }

  render() {
    if (!this.props.graphic.name){
      return (<h2>Sorry, something went terribly wrong.</h2>);
    }

    else {
      return (
        <div
          className='product-image'
          style={{backgroundImage: 'url(' + this.state.imgurl + ')'}}
        >
        </div>
      )
    }
  }
}

const mapStateToProps = (state, props)=>{
  return{
    graphics: state.graphics,
    graphic: state.graphic,

    products: state.products,
    product: state.product,

    colors: state.colors,
    color: state.color,

    sizes: state.sizes,
    size: state.size,

    graphicColor: state.graphicColor,

  }
};

const mapActionToProps = {
  updateGraphic: updateGraphic,
  updateProduct: updateProduct,
  updateColor: updateColor,
};

export default connect(mapStateToProps, mapActionToProps)(SizechartImage);
