import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

class Header extends Component {
  render() {
    return (
      <Link to="/" style={{ textDecoration: 'none' }}>
        <div className="header">
          <div className="header-text">GO BACK TO VIRTUAL WARDROBE</div>
        </div>
      </Link>
    )
  }
}

export default connect()(Header);
