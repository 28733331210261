import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';

import graphicsReducer from './reducers/graphics-reducer';
import colorsReducer from './reducers/colors-reducer';
import productsReducer from './reducers/products-reducer';
import sizesReducer from './reducers/sizes-reducer';
import graphicColorsReducer from './reducers/graphic-colors-reducer';

import activeGraphicReducer from './reducers/active-graphic-reducer';
import activeColorReducer from './reducers/active-color-reducer';
import activeProductReducer from './reducers/active-product-reducer';
import activeSizeReducer from './reducers/active-size-reducer';
import activeGraphicColorReducer from './reducers/active-graphic-color-reducer';



const allReducers = combineReducers({
  graphics: graphicsReducer,
  colors: colorsReducer,
  products: productsReducer,
  sizes: sizesReducer,
  graphicColors: graphicColorsReducer,


  graphic: activeGraphicReducer,
  color: activeColorReducer,
  product: activeProductReducer,
  size: activeSizeReducer,
  graphicColor: activeGraphicColorReducer,

});

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(<Provider store={store}><App />
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
