import React, { Component } from 'react';
import { connect } from 'react-redux';

import Color from './Color';
import Productlist from './Productlist';
import Graphiclist from './Graphiclist';

class Dashboard extends Component {
  render() {
    return (
      <div className={'option-window float-left left-dashboard'}>
        <div className='detail-block'>
          <div className='title'>
          </div>
          <div>Product</div>

          <Productlist />
        </div>
        <div className='detail-block'>
          <div className='title'>
          </div>
          <Graphiclist />
        </div>
      </div>
    )
  }
}

export default connect()(Dashboard);
