export default function () {
  return [
    {
      id: 0,
      color: 'black',
      hex: '#000',
      availableHoodieXS: true,

    },
    {
      id: 1,
      color: 'darkgrey',
      hex: '#474747',
      availableHoodieXS: true,
    },
    {
      id: 2,
      color: 'grey',
      hex: '#9b9b9b',
      availableHoodieXS: true,
    },
    {
      id: 3,
      color: 'beige',
      hex: '#e2c89c',
      availableHoodieXS: false,
    },
    {
      id: 4,
      color: 'olive',
      hex: '#495e21',
      availableHoodieXS: true,
    },
    {
      id: 5,
      color: 'blue',
      hex: '#1a3d75',
      availableHoodieXS: true,
    },
    {
      id: 6,
      color: 'plum',
      hex: '#510b42',
      availableHoodieXS: true,

    },
    {
      id: 7,
      color: 'red',
      hex: '#960000',
      availableHoodieXS: false,
    },
    {
      id: 8,
      color: 'skyblue',
      hex: '#a9f3ff',
      availableHoodieXS: false,
    },
    {
      id: 9,
      color: 'yellow',
      hex: '#fff9a9',
      availableHoodieXS: false,
    },
    {
      id: 10,
      color: 'white',
      hex: '#ffffff',
      availableHoodieXS: false,
    }
  ]
}
