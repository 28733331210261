export default function () {
  return [
    {
      id: 0,
      name: 'Pinetree',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 1,
      name: 'Trojan',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 2,
      name: 'Mother',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 3,
      name: 'Poppa',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 4,
      name: 'Fairytale',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#000'],
      fasc: true,
    },
    {
      id: 5,
      name: 'Pow',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 6,
      name: 'Arrows',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 7,
      name: 'Redemption',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 8,
      name: 'Deer',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 9,
      name: 'Deer2',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#000'],
      fasc: true,
    },
    {
      id: 10,
      name: 'Grizzly',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 11,
      name: 'Lumberjack',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 12,
      name: 'Visions',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 14,
      name: 'Authentic',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 16,
      name: 'Lunatic',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 17,
      name: 'Raven',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 18,
      name: 'Solid',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 19,
      name: 'Summit',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#fff'],
      fasc: true,
    },
    {
      id: 20,
      name: 'High',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#000'],
      fasc: true,
    },
    {
      id: 21,
      name: 'Leonardo',
      graphicGroup: 'FAS.C',
      multipleColors: false,
      colors: ['#000'],
      fasc: true,
    },
    {
      id: 30,
      name: 'National',
      graphicGroup: 'Gastein',
      multipleColors: true,
      colors: ['#fff', '#000'],
      fasc: true,
    },
    {
      id: 31,
      name: 'Compass',
      graphicGroup: 'Gastein',
      multipleColors: true,
      colors: ['#fff', '#000'],
      fasc: true,
    },
    {
      id: 32,
      name: 'Sporty',
      graphicGroup: 'Gastein',
      multipleColors: true,
      colors: ['#fff', '#000'],
      fasc: true,
    },
    {
      id: 33,
      name: 'Paradise',
      graphicGroup: 'Gastein',
      multipleColors: true,
      colors: ['#fff', '#000'],
      fasc: true,
    },
    {
      id: 34,
      name: 'Badgastein',
      graphicGroup: 'Gastein',
      multipleColors: true,
      colors: ['#fff', '#000'],
      fasc: true,
    },
    {
      id: 35,
      name: 'Kaiser',
      graphicGroup: 'Gastein',
      multipleColors: true,
      colors: ['#fff', '#000'],
      fasc: true,
    },
    {
      id: 36,
      name: 'Schareck',
      graphicGroup: 'Gastein',
      multipleColors: true,
      colors: ['#fff', '#000'],
      fasc: true,
    },
    {
      id: 37,
      name: 'Waterfall',
      graphicGroup: 'Gastein',
      multipleColors: true,
      colors: ['#fff', '#000'],
      fasc: true,
    },
    {
      id: 38,
      name: 'Spirit',
      graphicGroup: 'Gastein',
      multipleColors: true,
      colors: ['#fff', '#000'],
      fasc: true,
    },
    {
      id: 39,
      name: 'Dweller',
      graphicGroup: 'Gastein',
      multipleColors: true,
      colors: ['#fff', '#000'],
    }
  ]
}
