import { UPDATE_COLOR } from '../actions/color-actions';

export default function colorReducer(state = '', { type, payload
  }) {
  switch (type) {
    case UPDATE_COLOR :
      return payload;
    default:
      return state;
  }
}
