export default function () {
  return [
    {
      id: 0,
      category: 'Hoodie',
      sizeLimit: '6',
      availableColors: ['black', 'darkgrey', 'grey', 'blue', 'plum', 'olive', 'skyblue', 'yellow', 'red', 'beige'],
      availableGraphics: ['Trojan', 'Pinetree', 'Mother', 'Poppa', 'Fairytale', 'Pow', 'Redemption', 'Deer', 'Grizzly', 'Lumberjack', 'Visions',
      'Deer2', 'Authentic', 'Solid', 'Summit', 'Compass', 'Sporty', 'National',
      'Paradise', 'Badgastein', 'Kaiser', 'Waterfall', 'Dweller', 'Spirit'],
      availableSizes: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
      folder: 'hoodie',
      fasc: true,
    },
    {
      id: 1,
      category: 'T-Shirt',
      sizeLimit: '4',
      availableColors: ['black', 'darkgrey', 'white', 'blue', 'olive', 'red'],
      availableGraphics: ['Trojan', 'Pinetree', 'Mother', 'Poppa', 'Fairytale', 'Pow', 'Redemption', 'Deer', 'Grizzly', 'Lumberjack', 'Visions',
      'Deer2', 'Authentic', 'Solid', 'Summit', 'Compass', 'Sporty', 'National',
      'Paradise', 'Badgastein', 'Kaiser', 'Waterfall', 'Dweller', 'Spirit'],
      availableSizes: ['XS', 'S', 'M', 'L', 'XL', '2XL'],
      folder: 'tshirt',
      fasc: true,
    },
    /*
      {
      id: 2,
      category: 'Kids Hoodie',
      sizeLimit: '4',
      availableColors: ['black', 'olive', 'grey'],
      availableGraphics: ['Trojan', 'Pinetree', 'Solid', 'Paradise', 'Badgastein'],
      availableSizes: ['116 cm', '128 cm', '140 cm', '152 cm'],
      folder: 'kids_hoodie',
      fasc: false,
    },
    {
      id: 1,
      category: 'Kids T-Shirt',
      sizeLimit: '4',
      availableColors: ['black', 'white', 'olive', 'grey'],
      availableGraphics: ['Trojan', 'Pinetree', 'Mother', 'Poppa', 'Fairytale', 'Pow', 'Arrows', 'Redemption', 'Deer', 'Grizzly', 'Lumberjack', 'Visions',
      'Deer2', 'Authentic', 'Lunatic', 'Raven', 'Solid', 'Summit', 'High', 'Leonardo', 'Compass', 'Sporty', 'National',
      'Paradise', 'Badgastein', 'Kaiser', 'Schareck', 'Waterfall', 'Dweller', 'Spirit', 'Selina'],
      availableSizes: ['116 cm', '128 cm', '140 cm', '152 cm'],
      folder: 'kids_tshirt',
      fasc: false,
    },*/
  ]
}
