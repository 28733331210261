import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateColor} from '../actions/color-actions';
import {updateSize} from '../actions/size-actions';



class Size extends Component {

  createSizeList() {
      return this.props.sizes.map((size) =>{
        if (this.props.product.availableSizes.includes(size.size)) {
          if (this.props.product.category === "Hoodie" && size.size === 'XS' && this.props.color.availableHoodieXS === false) {
            return ''
          }
          else {
            return (
              <div key={size.size} onClick={() => this.props.updateSize(size)} >
                {this.props.size.id === size.id ?
                  <div className='swatch swatch-active'>{size.size}</div> :
                  <div className='swatch'>{size.size}</div>
                }
              </div>
            );
          }
        }
        else {
          return ''
        }
      });
    }

  render() {
    return (
      <section>
          {this.createSizeList()}
      </section>
    )
  }
}

const mapStateToProps = (state, props)=>{
  return{
    product: state.product,
    colors: state.colors,
    color: state.color,
    sizes: state.sizes,
    size: state.size,
  }
};

const mapActionToProps = {
  updateColor: updateColor,
  updateSize: updateSize,
};
export default connect(mapStateToProps, mapActionToProps)(Size);
