import { UPDATE_SIZE } from '../actions/size-actions';

export default function sizeReducer(state = '', { type, payload
  }) {
  switch (type) {
    case UPDATE_SIZE :
      return payload;
    default:
      return state;
  }
}
