import React, { Component } from 'react';
import { connect } from 'react-redux';

import {updateGraphic} from '../../actions/graphic-actions';
import {updateProduct} from '../../actions/product-actions';
import {updateColor} from '../../actions/color-actions';
import {updateSize} from '../../actions/size-actions';
import {updateGraphicColor} from '../../actions/graphic-color-actions';
import DatePicker from 'react-date-picker';

import Graphic from '../Graphic';
import ProductImage from '../ProductImage';
import SizechartImage from '../SizechartImage';
import FASC_RightDashboard from './FASC_RightDashboard';
import FASC_LeftDashboard from './FASC_LeftDashboard';
import SizechartList from '../SizechartList';
import FASC_Header from './FASC_Header';

class FASC_Main extends Component {
  constructor (props) {
    super(props);

    this.state = {
        imgurl: '',
        imgbg: '',
        imggraphic: '',
        sizechartOn: true,
        cordoverlay: '../../public/images/cords_overlay.png',
    }

    //Needed for the initial load: take first characters "welcome" parameters
    console.log(this.props.graphic);
    console.log(this.props.color);
    console.log(this.props.size);
    console.log(this.props.product);


    if (!this.props.graphic) {
      this.props.updateGraphic(this.props.graphics[0]);
      this.props.updateColor(this.props.colors[0]);
      this.props.updateProduct(this.props.products[0]);
      this.props.updateGraphicColor(this.props.graphicColors[0]);
      this.props.updateSize(this.props.sizes[1]);

    }


  }


  componentDidUpdate() {
    this.updateBG()
}


  updateBG(){
    const images = require.context('../../../public/images', true);
    const imgbg = images(`./bg.jpg`);
    const cordoverlay = images('./cordoverlay.png');


    if (this.state.imgbg !== imgbg) {
      this.setState({imgbg: imgbg})
      this.setState({cordoverlay: cordoverlay})
    }
  }

  render() {
    if (!this.props.graphic.name){
      return (<h2>Sorry, something went terribly wrong.</h2>);
    }

    else {
      return (
        <div>
          <FASC_Header />
          <FASC_LeftDashboard />
          <FASC_RightDashboard />
          <div className='bg-image' style={{backgroundImage: 'url(' + this.state.imgbg + ')'}}>
          </div>

          <ProductImage />
          <Graphic />

          {this.props.product.category === 'Hoodie' ?
            <div className='product-image' style={{backgroundImage: 'url(' + this.state.cordoverlay + ')'}}>
            </div>
            : ''
          }

        </div>
      )
    }
  }
}

const mapStateToProps = (state, props)=>{
  return{
    graphics: state.graphics,
    graphic: state.graphic,

    products: state.products,
    product: state.product,

    colors: state.colors,
    color: state.color,

    sizes: state.sizes,
    size: state.size,

    graphicColor: state.graphicColor,
    graphicColors: state.graphicColors,

  }
};

const mapActionToProps = {
  updateGraphic: updateGraphic,
  updateProduct: updateProduct,
  updateColor: updateColor,
  updateSize: updateSize,
  updateGraphicColor: updateGraphicColor,
};

export default connect(mapStateToProps, mapActionToProps)(FASC_Main);
