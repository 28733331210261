import { UPDATE_GRAPHIC_COLOR } from '../actions/graphic-color-actions';

export default function graphicColorReducer(state = '', { type, payload
  }) {
  switch (type) {
    case UPDATE_GRAPHIC_COLOR :
      return payload;
    default:
      return state;
  }
}
