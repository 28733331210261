import React, { Component } from 'react';
import { connect } from 'react-redux';

import Color from '../Color';
import FASC_Productlist from './FASC_Productlist';
import FASC_Graphiclist from './FASC_Graphiclist';

class FASC_LeftDashboard extends Component {
  render() {
    return (
      <div className={'option-window float-left left-dashboard'}>
        <div className='detail-block'>
          <div className='title'>
          </div>
          <div>Product</div>

          <FASC_Productlist />
        </div>
        <div className='detail-block'>
          <div className='title'>
          </div>
          <FASC_Graphiclist />
        </div>
      </div>
    )
  }
}

export default connect()(FASC_LeftDashboard);
