import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateProduct} from '../../actions/product-actions';
import {updateColor} from '../../actions/color-actions';

//Product function is not available for bachelor thesis
//Functionality left inside for future development

class Selina_Productlist extends Component {
  updateProduct(product){
    this.props.updateProduct(product)
    this.props.colors.map((color) => {
        product.availableColors[0] === color ?
        this.props.updateColor(color) :
        this.props.updateColor(this.props.colors[0])
        return ''
    });
  }

  createProductsList() {
    return this.props.products.map((product) => {
      if (product.fasc === true) {
        return (
            <div key={product.id} onClick={() => this.updateProduct(product)} >
              {this.props.product.category === product.category ?
                <div className='swatch swatch-active'>{product.category}</div> :
                <div className='swatch'>{product.category}</div>
              }
            </div>
        );
      }
      else {return ''}
    });
  }

  render() {
    return (
      <section>
          {this.createProductsList()}
      </section>
    )
  }
}

const mapStateToProps = (state, props)=>{
  return{
    character: state.character,
    products: state.products,
    product: state.product,
    color: state.color,
   colors: state.colors,
  }
};

const mapActionToProps = {
  updateProduct: updateProduct,
  updateColor: updateColor,
};

export default connect(mapStateToProps, mapActionToProps)(Selina_Productlist);
