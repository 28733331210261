import React, { Component } from 'react';
import { connect } from 'react-redux';

import {updateGraphic} from '../actions/graphic-actions';
import {updateProduct} from '../actions/product-actions';
import {updateColor} from '../actions/color-actions';
import {updateSize} from '../actions/size-actions';
import {updateGraphicColor} from '../actions/graphic-color-actions';
import DatePicker from 'react-date-picker';

import LeftDashboard from './LeftDashboard';
import Graphic from './Graphic';
import RightDashboard from './RightDashboard';
import Main from './Main';

import emailjs from 'emailjs-com';
import Popup from "reactjs-popup";



function sendEmail(e) {
  e.preventDefault();

  emailjs.sendForm('valters_fasc_at', 'gwond', e.target, 'user_jjmRMDEFrj4Ml7guVwza0')
    .then((result) => {
        if (document.getElementById('content')) {

          if (document.getElementById('content').style.display == 'none') {
              document.getElementById('content').style.display = 'block';
              document.getElementById('success').style.display = 'none';
          }
          else {
              document.getElementById('content').style.display = 'none';
              document.getElementById('success').style.display = 'block';
          }
        }
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
}


class Welcome extends Component {
  constructor (props) {
    super(props);

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    this.state = {
        date: tomorrow,
        imgurl: '',
        imgbg: '',
        cordoverlay: '../public/images/cords_overlay.png',
        gschaeftl_logo: '../public/images/gschaeftl_logo.png',
    }

    //Needed for the initial load: take first characters "welcome" parameters
    this.props.updateGraphic(this.props.graphics[0]);
    this.props.updateColor(this.props.colors[0]);
    this.props.updateProduct(this.props.products[0]);
    this.props.updateSize(this.props.sizes[1]);
    this.props.updateGraphicColor(this.props.graphicColors[0]);

  }

  onChange = date => this.setState({ date })


  componentDidUpdate() {
    this.updateImgUrl()
    this.updateBG()
  }

  updateImgUrl(){
    const images = require.context('../../public/images', true);
    const imgsrc = images(`./${this.props.product.folder}/${this.props.color.color}.png`);
    const imgdef = images(`./${this.props.product.folder}/black.png`);

    if (this.state.imgurl !== imgsrc) {
      this.setState({imgurl: imgsrc})
      if (this.state.imgsrc){
        this.setState({imgurl: imgdef})
      }
    }
  }
  updateBG(){
    const images = require.context('../../public/images', true);
    const imgbg = images(`./bg.jpg`);
    const cordoverlay = images('./cordoverlay.png');
    const gschaeftl_logo = images('./gschaeftl_logo.png');


    if (this.state.imgbg !== imgbg) {
      this.setState({imgbg: imgbg})
      this.setState({cordoverlay: cordoverlay})
      this.setState({gschaeftl_logo: gschaeftl_logo})

    }
  }

  render() {
    if (!this.props.graphic.name){
      return (<h2>Sorry, something went terribly wrong.</h2>);
    }

    else {
      return (
        <div>


        <div className="intro-box">
          <div
            className='popup-logo'
            style={{backgroundImage: 'url(' + this.state.gschaeftl_logo + ')'}}
          ></div>
          <h2>Hey, You!</h2>
          <h3>It's nice you decided to have a look at our Gastein Clothing.</h3>
          <h3>1. By clicking on "START", you can create your favorite style with the right graphic, color and size.</h3>
          <h3>2. Once you've found your combination, go ahead and click the "NEXT STEP" button.</h3>
          <h3>3. There you can insert your contact info - your Name, phone number and optional info.</h3>
          <h3>4. All you have to do next is hit the "ORDER" button and your hoodie will be ready to pick up at Gschäftl. <br></br> <a target="_blank" href="https://goo.gl/maps/oBxjYFQahii1awoQ6"> Click here to see where that is. </a></h3>

          <button onClick={event =>  window.location.href='/main'} className="order-button">START
          </button>
        </div>
        <div
          className='bg-image'
          style={{backgroundImage: 'url(' + this.state.imgbg + ')'}}
        ></div>


        </div>
      )
    }
  }
}

const mapStateToProps = (state, props)=>{
  return{
    graphics: state.graphics,
    graphic: state.graphic,

    products: state.products,
    product: state.product,

    colors: state.colors,
    color: state.color,

    sizes: state.sizes,
    size: state.size,

    graphicColor: state.graphicColor,
    graphicColors: state.graphicColors,

  }
};

const mapActionToProps = {
  updateGraphic: updateGraphic,
  updateProduct: updateProduct,
  updateColor: updateColor,
  updateSize: updateSize,
  updateGraphicColor: updateGraphicColor,
};

export default connect(mapStateToProps, mapActionToProps)(Welcome);
