import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from './Header';

class Survey extends Component {
  render() {
    return (
      <div>
        <Header/>
        <div className="survey-container">
          <iframe
            title="survey"
            frameborder="0"
            id="iframeA3S9B9S8R0V2S8G9L"
            src="https://www.survio.com/survey/i/A3S9B9S8R0V2S8G9L"
            width="100%"
            height="100%"
            >
          </iframe>
        </div>
      </div>
    )
  }
}

export default connect()(Survey);
