export default function () {
  return [
    {
      id: 0,
      color: 'white',
      hex: '#fff',
    },
    {
      id: 1,
      color: 'black',
      hex: '#000',
    },
  ]
}
