import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateColor} from '../actions/color-actions';



class Color extends Component {
  createColorList() {
      return this.props.colors.map((color) =>{
        if (this.props.product.availableColors.includes(color.color)) {
          return (
            <div key={color.id} onClick={() => this.props.updateColor(color)} >
              {this.props.color.id === color.id ?
                <div className='colorswatch colorswatch-active' style={{backgroundColor: color.hex }}></div> :
                <div className='colorswatch' style={{backgroundColor: color.hex }}></div>
              }
            </div>
          );
        }
        return '';
      });
    }

  render() {
    return (
      <section>
          {this.createColorList()}
      </section>
    )
  }
}

const mapStateToProps = (state, props)=>{
  return{
    product: state.product,
    colors: state.colors,
    color: state.color,
  }
};

const mapActionToProps = {
  updateColor: updateColor,
};
export default connect(mapStateToProps, mapActionToProps)(Color);
