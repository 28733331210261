import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateGraphic} from '../actions/graphic-actions';
import {updateGraphicColor} from '../actions/graphic-color-actions';

class Graphiclist extends Component {
  constructor (props) {
    super(props);

    this.state = {
        graphicGroup: 'Gastein',
    }

  }

  updateGraphic(graphic){
    this.props.updateGraphic(graphic);

    this.props.graphicColors.map((color) => {
      if (color.hex === graphic.colors[0]) {
        this.props.updateGraphicColor(color)
      }
      return ''
    });
  }

  createGraphicList() {
    return this.props.graphics.map((graphic) => {
      if (this.state.graphicGroup === graphic.graphicGroup && this.props.product.availableGraphics.includes(graphic.name)) {
        return (
          <div key={graphic.id} onClick={() => this.updateGraphic(graphic)} >
            {this.props.graphic.id === graphic.id ?
              <div className='swatch swatch-active graphiclist'>{graphic.name}</div> :
              <div className='swatch graphiclist'>{graphic.name}</div>
            }
            </div>
        );
      }
    });
  }

  render() {
    return (
      <section>
      <div>Brand</div>

        <div className={this.state.graphicGroup === 'Gastein' ?
        'swatch swatch-active' : 'swatch'}
        onClick={() => this.setState({graphicGroup: 'Gastein'})}>Gastein</div>
        <div className={this.state.graphicGroup === 'FAS.C' ?
        'swatch swatch-active' : 'swatch'}
        onClick={() => this.setState({graphicGroup: 'FAS.C'})}>FAS.C</div>

        <div className='detail-block'>
        <div>Graphics</div>
        <div className='graphiclist-wrapper'>

        {this.createGraphicList()}
        </div>
        </div>

        <div className='detail-block'>

          {this.props.graphic.colors.length > 1 ?
            <div>
              <div>Color</div>
              {this.props.graphicColors.map((color) => {
                return (
                  <div key={color.id} onClick={() => this.props.updateGraphicColor(color)} >
                      <div style={{backgroundColor: color.hex}} className='colorswatch graphic-color-swatch'></div>
                  </div>
                );
              })
              }
            </div> :
            ''
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state, props)=>{
  return{
    graphics: state.graphics,
    graphic: state.graphic,
    product: state.product,
    graphicColor: state.graphicColor,
    graphicColors: state.graphicColors,
  }
};

const mapActionToProps = {
  updateGraphic: updateGraphic,
  updateGraphicColor: updateGraphicColor,
};

export default connect(mapStateToProps, mapActionToProps)(Graphiclist);
