import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router} from 'react-router-dom';

import Main from './components/Main'
import Survey from './components/Survey'
import Description from './components/Description'
import FASC_Main from './components/FASC/FASC_Main'
import FASC_Sizechart from './components/FASC/FASC_Sizechart'
import Selina_Main from './components/Selina/Selina_Main'
import Selina from './components/Selina/Selina'
import Welcome from './components/Welcome'



require('./App.scss');

class App extends Component {
  render() {
    return (
      <Router>
        <Route exact path={"/"} component={Welcome} />
        <Route exact path={"/main"} component={Main} />
        <Route exact path={"/fasc"} component={FASC_Main} />
        <Route exact path={"/selina"} component={Selina} />
        <Route exact path={"/selina_main"} component={Selina_Main} />
        <Route exact path={"/sizechart"} component={FASC_Sizechart} />
        <Route exact path={"/survey"} component={Survey} />
        <Route exact path={"/productdescription"} component={Description} />
      </Router>
    );
  }
}

export default connect()(App);
