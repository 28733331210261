import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from './Header';

class Survey extends Component {
  render() {
    return (

        <div class="grid-container">

          <div class="Descr-1">
            <img className="img-descr" src="https://cdn.webshopapp.com/shops/18819/files/308432789/moneypocket.jpg"/>
            <div className="text-descr">A special pocket for your favorite stone, or whatever you want to put in there. We don't judge!</div>
          </div>

          <div class="Descr-2">
            <img className="img-descr" src="https://cdn.webshopapp.com/shops/18819/files/308432777/chords2.jpg"/>
            <div className="text-descr">Nice and thick cords for something you can grab on to in times of unease.</div>
          </div>

          <div class="Descr-3">
            <img className="img-descr" src="https://cdn.webshopapp.com/shops/18819/files/308432774/skipasspocket-green.jpg"/>
            <div className="text-descr">Of course, there is a special pocket for your ski pass! A convenient pouch for the essentials.</div>
          </div>

          <div class="Descr-4">
            <img className="img-descr" src="https://cdn.webshopapp.com/shops/18819/files/308432780/thumbhole.jpg"/>
            <div className="text-descr">Perfect holes for your thumbs so that the hoodie sits even more comfortable. Truly advantageous under your gloves!</div>
          </div>

          <div class="Descr-5">
            <img className="img-descr" src="https://cdn.webshopapp.com/shops/18819/files/308432783/freedom.jpg"/>
            <div className="text-descr">Hidden life lessons, we want you to feel free in your hoodie.</div>
          </div>

          <div class="Descr-6">
            <img className="img-descr" src="https://cdn.webshopapp.com/shops/18819/files/308646275/facemask.jpg"/>
            <div className="text-descr">The facemask is there for you when you need it. Covers your face perfectly in rough conditions.</div>
          </div>

          <div class="Descr-7">
            <img className="img-descr" src="https://cdn.webshopapp.com/shops/18819/files/308646272/facemask2.jpg"/>
            <div className="text-descr">The facemask is also completely detachable and adjustable to three heights.</div>
          </div>

          <div class="Descr-8">
            <img className="img-descr" src="https://cdn.webshopapp.com/shops/18819/files/308432792/colors.jpg"/>
            <div className="text-descr">FAS.C hoodies are always being improved. We hope you enjoy them as much as we do.</div>
          </div>
          <div className="clear-both"></div>

        </div>

    )
  }
}

export default connect()(Survey);
