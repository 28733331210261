import { UPDATE_PRODUCT } from '../actions/product-actions';

export default function productReducer(state = '', { type, payload
  }) {
  switch (type) {
    case UPDATE_PRODUCT :
      return payload;
    default:
      return state;
  }
}
